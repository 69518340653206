<template>
  <div class="content">
        <div class="main-screen-section inside screen6"></div>

        <div class="container inside-page content-section">
          <h1>Лечение и профилактика мигрени</h1>
          <div class="content-text text-size-big">
            <p>
              Основные цели лечения мигрени традиционно включали в себя снятие
              боли, восстановление функций и снижение частоты головной боли.
              Дополнительно целью может быть предотвращение прогрессирования ЭМ
              к ХМ.
            </p>
          </div>
          <br>
          <div class="content-text text-size-middle">
            <h3>Немедикаментозные методы</h3>
            <p>
              В первую очередь подразумевают контроль над триггерами и
              поведенческую терапию. <br />
              На практике используются следующие методы:
            </p>

            <ul class="list-block">
              <li>
                <p>Выявление и исключение триггеров;</p>
              </li>
              <li>
                <p>Активности, способствующие релаксации;</p>
              </li>
              <li>
                <p>
                  Гипнотерапия (терапевтическое применение гипноза; применяется
                  для лечения фобий и тревоги, управления болью, устранения
                  привычек и зависимостей);
                </p>
              </li>
              <li>
                <p>
                  Когнитивно-поведенческая терапия (КПТ, форма психотерапии,
                  которая стимулирует пациентов на выявление и получение опыта
                  из неэффективных шаблонов поведения посредством осознанного
                  выбора в пользу здоровых изменений);
                </p>
              </li>
              <li>
                <p>
                  Обучение технике биоуправления (обучающая техника для развития
                  способности контролировать вегетативную (автономную) нервную
                  систему; пациент может контролировать частоту сердечных
                  сокращений, артериальное давление и температуру кожи, а также
                  может расслаблять определенные мышцы).
                </p>
              </li>
            </ul>

            <br />
            <h3>Медикаментозные терапии</h3>
            <p>
              Основаны на назначении лекарственных препаратов для лечения
              мигрени. Лекарственные препараты, применяемые при мигрени, можно
              разделить на неотложные и профилактические.
            </p>
            <p>
              В некоторых случаях пациентам с эпизодической мигренью достаточно
              немедикаментозных методов лечения и неотложной терапии; однако
              пациентам с хронической мигренью, как правило, требуется
              комплексный подход, подразумевающий использование профилактических
              и неотложных терапий.
            </p>
            <br />
            <h3>Таргетная терапия</h3>
            <p>
              Ни одно из применяемых в настоящее время лекарственных средств
              (антидепрессанты, антиконвульсанты, бета-адреноблокаторы,
              блокаторы кальциевых каналов) не является специфичным к мигрени.
            </p>
            <p>
              Однако последние достижения в понимании патофизиологии мигрени
              дали возможность <br class="visible-xs" />
              для разработки новых фармакотерапевтических подходов, специально
              направленных на различные нейрональные механизмы .
            </p>
            <p>
              Главным новшеством стал таргетный подход — нацеленность на
              специфические нейробиологические мишени. В настоящее время
              лидирующей является стратегия, направленная на нейробиологию CGRP.
            </p>
          </div>

          <div class="video-block">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/sz3c4Y_ahSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!-- <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="video-block">
                    <img src="img/pic8.jpg" class="img-responsive" />
                    <span class="play-ico"></span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="video-block">
                    <img src="img/video.png" class="img-responsive" />
                    <span class="play-ico"></span>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Add Arrows -->
            <!-- <div class="next"></div>
            <div class="prev"></div> -->
          </div>

          <div class="content-text text-size-middle">
            <div class="title-green">Фреманезумаб (Аджови)</div>

            <p>
              Фреманезумаб представляет собой полностью гуманизированное
              моноклональное антитело, специфичное к нейропептиду CGRP
              (кальцитонин-ген родственный пептид), играющему важную роль в
              развитии хронической и эпизодической мигрени.
            </p>
            <p>
              Аджови – инъекционный препарат с возможностью введения его
              пациентам по гибкой схеме. Препарат доказал свою эффективность для
              предотвращения приступов как при эпизодической, так и при
              хронической мигрени. Аджови позволяет части пациентов достичь
              полного исчезновения приступов.
            </p>
            <br />

            <h5 class="mb30">
              Отличие Моноклональных антител (МАТ) <br />
              от традиционных средств профилактики мигрени
            </h5>

            <table class="mat-table hidden-xs">
              <thead>
                <tr>
                  <td></td>
                  <td>Традиционные ЛС</td>
                  <td>МAT для ЭМ и ХМ</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Специфичность</td>
                  <td>Нет</td>
                  <td>Да</td>
                </tr>
                <tr>
                  <td>Способ введения</td>
                  <td>П\о</td>
                  <td>П/к или в/в</td>
                </tr>
                <tr>
                  <td>Необходимость титрования дозы</td>
                  <td>Да</td>
                  <td>Нет</td>
                </tr>
                <tr>
                  <td>Кратность приема</td>
                  <td>Ежедневно</td>
                  <td>Ежемесячно/ежеквартально</td>
                </tr>
                <tr>
                  <td>Начало действия</td>
                  <td>Медленное (недели)</td>
                  <td>Быстрое (дни)</td>
                </tr>
                <tr>
                  <td>Побочные эффекты</td>
                  <td>
                    Прибавка веса,<br />
                    изменения настроения, Сонливость, слабость, Сексуальная
                    дисфункция, Головокружение
                  </td>
                  <td>Нет</td>
                </tr>
              </tbody>
            </table>

            <div class="table-img visible-xs">
              <a data-fancybox="gallery" href="img/table1.png"
                ><img src="img/table1.png" alt="img"
              /></a>
              <span class="zoom-ico"></span>
            </div>

            <h3 class="mb30">Механизм действия</h3>

            <!-- <p>
              Ни одно из применяемых в настоящее время лекарственных средств
              (антидепрессанты, антиконвульсанты, бета-адреноблокаторы,
              блокаторы кальциевых каналов) не является специфичным к мигрени.
              Однако последние достижения в понимании патофизиологии мигрени
              дали возможность для разработки новых фармакотерапевтических
              подходов, специально направленных на различные нейрональные
              механизмы.
            </p>
            <p>
              Главным новшеством стал таргетный подход — нацеленность на
              специфические нейробиологические мишени. В настоящее время
              лидирующей является стратегия, направленная на нейробиологию CGRP.
            </p> -->

            <div class="video-block">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/s3DnsGMSjZY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <!-- <span class="play-ico"></span> -->
            </div>

            <h3>Данные исследований</h3>
            <p>
              В ходе клинических исследований, менее чем у 1% пациентов были
              зарегистрированы реакции гиперчувствительности при применении
              фреманезумаба. При возникновении реакции гиперчувствительности,
              необходимо рассмотреть прекращение введения фреманезумаба и начать
              соответствующую терапию.
            </p>
            <p>
              Вспомогательные вещества: в этом лекарственном средстве содержится
              менее 1 ммоль натрия (23 мг) на дозу, таким образом оно является
              практически «безнатриевым». Один предварительно заполненный шприц
              содержит в качестве стабилизатора 99 мг сахарозы.
            </p>
            <p>
              Взаимодействия с другими лекарственными препаратами: официальных
              клинических исследований по лекарственному взаимодействию с Аджови
              не проводилось. На основании характеристик фреманезумаба не
              ожидается фармакокинетических лекарственных взаимодействий. Кроме
              того, во время клинических исследований, одновременное применение
              лекарственных средств (в частности анальгетиков, спорыньи и
              триптанов) для лечения приступа мигрени и для профилактики мигрени
              <br class="hidden-xs" />
              не повлияло на фармакокинетику фреманезумаба.
            </p>
            <br />
            <div class="instructions">
              <a
                href="img/ОХЛП_Аджови_р_р_подкожный_225мг_1_5мл_03_04_2021_рус_final.pdf"
                class="instruction-link"
                target="_blank"
                >ОХЛП рус.</a
              >
              <!-- https://teva-ajovy-api.rocketfirm.net/storage/files/pdf/general-info.pdf -->
              <br class="visible-xs" />
              <a href="img/ОХЛП_Аджови_р_р_подкожный_225мг_1_5мл_03_04_2021_каз_final.pdf" target="_blank" class="instruction-link">ОХЛП каз.</a>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'therapy',
  metaInfo: {
    title: 'Лечение',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  }
}
</script>
